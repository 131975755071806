.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.noIssues {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.sectionHeaderContainer {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    width: 100%;
    height: 24px;
    padding: 0 10px;
}

.sectionHeaderContainer > .title {
    color: #101010;
}

.sectionHeaderContainer > .subTitle {
    /* background-color: #fafafa; */
    text-transform: uppercase;
    color: #101010;
}

.myInlineDecoration {
	color: red !important;
	cursor: pointer;
	text-decoration: underline;
	font-weight: bold;
	font-style: oblique;
}

.myLineDecoration {
	background: lightblue;
	width: 5px !important;
	margin-left: 3px;
}