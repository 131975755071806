/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features Actions
*/

.runButtonContainer {
    overflow-x: scroll;
}

.runButtonContainer::-webkit-scrollbar {
    display: none;
}