.headerContainer {
    display: flex;
    width: 100%;
    background-color: #FFFFFF;
    height: 60px;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    z-index: 11;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    position: sticky;
    top: 0;
    box-sizing: border-box;
}

.headerContainer > .orgContainer {
    display: flex;
    align-items: center;
}

.logoContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logoImg {
    height: 40px;
    margin: 0 0 0 20px;
    margin-right: 10px;
}

.headerContainer > .userContainer {
    display: flex;
    /* background-color: #00ff00; */
    align-items: center;
    padding: 20px;
}

.headerContainer > .userContainer > a {
    color: #333;
    text-decoration: none;
    padding: 0 10px;
}

.tabsContainer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    z-index: 10;
}