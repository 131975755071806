@import '../../components/constants/Constants.css';

.hexagonGridContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
    /* background-color: rgb(235, 255, 255); */
    overflow: scroll;
    padding: 20px;
    box-shadow:  0  0 10px rgba(10, 10, 10, 0.1) inset;
    box-sizing: border-box;
}

.hexagonGridContainer > div {
    position: relative;
    overflow: visible;
}

.hexagon {
    /* fill: rgba(43,82,112, 0.3); */
    fill: var(--color-ok);
    stroke-width: 1;
    cursor: pointer;
}

.hexagonWarn {
    fill: var(--color-warn);
    fill-opacity: 0.5;
    stroke: var(--color-warn);
}

.hexagon:hover {
    fill-opacity: 0.9;
}

.hexagonDanger {
    fill: var(--color-danger);
    fill-opacity: 0.5;
    stroke: var(--color-danger);
}

.hexagonOk {
    fill: var(--color-ok);
    fill-opacity: 0.5;
    stroke: var(--color-ok);
}

.hexagon:hover .hexagonText {
    fill: #FAFAFA;
    stroke: #FAFAFA;
}

.hexagon:active {
    cursor: grabbing;
}

.hexagonGridContainer:active {
    cursor: grabbing;
}

.hexagonText {
    font-size: 0.75em;
    pointer-events: none;
    user-select: none;
    stroke-width: 0.4px;
}

.root {
    font-family: consolas, sans-serif;
    text-align: center;
    position: relative;
    height: 400px;
    width: 50%;
    padding: 0;
    margin: 0;
    border: 0;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 200px;
    right: calc(50% - 100px);
    left: calc(50% - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14;
    color: #333333;
    text-align: center;
    pointer-events: none;
}

.filtersContainer {

    transition: all 5s ease;
}