#terminal-container {
    width: auto;
    height: calc(100% - 30px);
    margin: 0;
    padding: 0;
    background-color: black;
}

.terminal {
    padding: 5px;
    height: calc(100% - 10px);
}

.handleContainer {
    cursor: ns-resize;
    top: 0px;
    width: 100%;
    height: 24px;
    position: absolute;
    color: #666;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .resizeHandle {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 100px;
      right: 100px;
      text-align: center;
  }
  
  .controlButtonContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 5px;
      padding-right: 10px;
      cursor: default;
  }
  
  .controlButtons {
      height: 0.75em;
      width: 0.75em;  
      cursor: pointer;
      /* background-color: #333!important; */
      margin: 0 2px!important;
  }
  
  .controlButtonIcons {
      height: 0.75em!important;
      width: 0.75em!important;
      /* color: wheat; */
      color: #666;
  }