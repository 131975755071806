.container {
    width: 650px;
    padding: 0 20px 50px 20px;
}

.header {
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    padding-top: 20px;
    z-index: 100;
}