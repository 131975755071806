.container {
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 100vh;
  min-width: 100%;
  background-color: aliceblue;
}

.formContainer {
  width: 400px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 50px;
  padding-bottom: 40px;
  background-color: white;
}

.logoImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.logoImg {
  width: 150px;
  height: 150px;
}

.headerText {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.subHeaderText {
  font-size: 1rem;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20%;
}

.loginButton {
  width: 90%;
}