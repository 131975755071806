.newIntegrationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 300px;
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
}