/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features RunDetails
*/

:global(ai-dag-scaffold) {
    width: 650px;
    max-height: calc(100vh - 120px);
    height: 650px;
    margin-top: 20px;
    background: white;
}

.container {
    width: 690px;
    height: 100vh;
    padding: 0 20px 10px 20px;
    background-color: #FFFFFF;
    box-shadow: inset 0 0 0 1px #E5E5E5;
    overflow: auto;
}

.header {
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    padding-top: 20px;
    z-index: 100;
}