/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/component -o src/components CollapsibleContainer
*/

.arrow {
    transition-duration: 0.2s!important;
    transition-property: transform!important;
    transition-timing-function: ease!important;
}

.open {
    transform: rotate(-180deg);
}

.closed {
    transform: rotate(360deg);
}

.header {
    cursor: pointer;
    background: #FFFFFF; 
    position: sticky; 
    top: 50px;
    padding-top: 10px;
    z-index: 99;
}