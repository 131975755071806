/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/component -o src/components RepositoryScore
*/

@import '../constants/Constants.css';

.score {
    width: 32px;
    height: 30px;
    background-color: black;
    color: #FFFFFF;
    border-radius: 2px;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.danger {
    background-color: var(--color-danger);
}

.warn {
    background-color: var(--color-warn);
}

.ok {
    background-color: var(--color-ok);
}